@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

$breakpoint-md: 970px;
$breakpoint-sm: 690px;
$breakpoint-galaxyfold: 300px;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}
