@import '../../../index.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  .block {
    width: 40%;
    height: 100%;
    .textContainer {
      height: 403px;
      width: 415px;
      margin: 107px 20% 154px 15%;
      .title,
      .MIW,
      .paragraph {
        padding: 0px 10px;
      }
      .title {
        font-size: 64px;
        font-family: 'Signika', sans-serif;
      }
      .MIW {
        color: #fe022a;
        font-size: 94px;
        font-family: 'Signika', sans-serif;
      }
      .paragraph {
        font-family: 'Rajdhani', sans-serif;
      }
      .button {
        background-color: #fe022a;
        color: #ffffff;
        width: 100%;
        text-align: center;
        box-shadow: 0px 1px 3px #00000033;
        border-radius: 4px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin: 20px 10px;
        padding: 20px 0px;
        letter-spacing: 1.25px;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
        a {
          color: white;
          text-decoration: none;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .background {
    background-image: url('../../../assets/MiddleSection/background.jpg');
    width: 60%;
    height: 100.1%;
    background-repeat: no-repeat;
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    clip-path: polygon(23% 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@media (max-width: $breakpoint-md) {
  .container {
    flex-direction: column;
    height: 1300px;
    .block {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 35%;
      .textContainer {
        margin: 20px 10% 0px 10%;
      }
    }
    .background {
      margin-bottom: -1px;
      height: 65%;
      width: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .container > .block {
    height: 35%;
    .textContainer {
      width: 300px;
      margin: 20px 0 0px 0;
      .title,
      .MIW,
      .paragraph {
        padding: 0px 0px;
      }
      .title {
        font-size: 40px;
      }
      .MIW {
        font-size: 50px;
      }
      .button {
        margin: 20px 0px;
        padding: 20px 0px;
      }
    }
  }
  .background {
    height: 65%;
  }
}
