@import '../../../index.scss';

.container {
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  .buttons {
    display: flex;
    justify-content: center;
    padding-top: 230px;
    .back,
    .next {
      width: 200px;
      margin: 40px 30px;
    }
  }
}

h2 {
  text-align: center;
  margin-top: -100px;
  margin-bottom: 50px;
}

@media (max-width: $breakpoint-md) {
  .container > .buttons {
    padding-top: 350px;
  }
}

@media (max-width: $breakpoint-sm) {
  .container > .buttons {
    padding-top: 420px;
  }
  h2 {
    margin-top: 0px;
  }
}
