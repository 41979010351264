@import '../../../index.scss';

.container {
  background-color: #fe022a;
  height: 550px;
  text-align: center;
  .block {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .icon {
      margin-bottom: 20px;
      transform: scale(0.9);
    }
    .text {
      color: #ffffff;
      font-size: 20px;
      text-align: center;
      font-family: 'Rajdhani', sans-serif;
    }
    .button {
      font-family: 'Roboto', sans-serif;
      letter-spacing: 1.25px;
      color: #fe022a;
      padding: 10px 0px;
      background-color: #ffffff;
      margin-top: 20px;
      font-weight: 500;
      font-size: 14px;
      width: 421px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 3px #00000033;
      border-radius: 4px;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      .dll {
        margin-left: 15px;
        transform: scale(0.8);
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .container > .block {
    .text {
      width: 275px;
    }
    .button {
      width: 275px;
    }
  }
}
