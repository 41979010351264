@import '../../../../index.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .step {
    margin: 0px 0 10px 0;
    color: #124265;
    font-family: 'Raleway', sans-serif;
  }
  .content {
    letter-spacing: 0.5px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
  }
}
.card {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: $breakpoint-sm) {
  .card {
    margin-top: 20px;
  }
}
