@import '../../../index.scss';

.container {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  .title {
    font-size: 56px;
    line-height: 72px;
    color: #124265;
    font-family: 'Poppins', sans-serif;
    margin-top: -70px;
    text-align: center;
  }
  .icon {
    margin-top: -20px;
    transform: scale(0.7);
  }
  .text {
    font-family: 'Raleway', sans-serif;
    color: #5e5e5e;
    font-size: 22px;
    text-align: center;
    p {
      margin: 11px;
    }
  }
  .button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 14px 50px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 30px;
    color: #fff;
    background: #2487ce;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .container {
    margin-top: 150px;
    .title {
      margin-top: 0px;
      font-size: 40px;
    }
    .text {
      font-size: 20px;
    }
  }
}
